/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import CircularProgress from '@mui/material/CircularProgress';

export type SpinnerProps = {
  center?: boolean;
  color?: string;
  size?: number;
};

export const Spinner: React.FC<SpinnerProps> = ({
  center,
  color,
  size = 32,
}) => {
  const computedStyles = {
    spinner: css({
      '&.MuiCircularProgress-root': {
        color,
      },
    }),
  };
  return (
    <div css={center ? styles.center : undefined}>
      <CircularProgress size={size} sx={computedStyles.spinner} />
    </div>
  );
};

const styles = {
  center: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};
